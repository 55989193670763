import React, { useState } from 'react'
import Layout from '../../components/layout'
import { Container, Row, Col, Modal, ModalHeader, ModalBody, } from 'reactstrap'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import Img from "gatsby-image"
import Video from '../../video/ceo.mp4'
import Video2 from '../../video/ceo2.mp4'
import Play from '../../images/ceo/play.svg'
import Ceo1 from '../../images/ceo/ceo1.jpg'
import Ceo2 from '../../images/ceo/ceo2.jpg'
import Ceo3 from '../../images/ceo/ceo3.jpg'
import Award from '../../images/ceo/award.jpg'
import YouTube from 'react-youtube';
import AudioPlayer from 'react-h5-audio-player';
import AudioImg from '../../images/audi-player.jpg'

import BlogForm from './Blog/BlogForm'


const AboutCEO = (props) => {
    const {
        // buttonLabel,
        className
    } = props;
    const youTubeOpts = {
        // height: '100%',
        // width: '100%',
        playerVars: {
            autoplay: 1,
            modestbranding: 0,
            mute: 1,
            loop: 1,
            // playlist: 'W1gxesz0b1g',
            autohide: 1
        }
    }

    const [modal, setModal] = useState(false);
    const [nestedModal, setNestedModal] = useState(false);
    const [closeAll, setCloseAll] = useState(false);

    const [nestedModal1, setNestedModal1] = useState(false);
    const [closeAll1, setCloseAll1] = useState(false);

    const [nestedModal2, setNestedModal2] = useState(false);
    const [closeAll2, setCloseAll2] = useState(false);

    const [modal3, setModal3] = useState(false);
    const toggle3 = () => setModal3(!modal3);

    const toggle = () => setModal(!modal);

    const toggleNested = () => {
        setNestedModal(!nestedModal);
        setCloseAll(false);
    }

    const toggleNested1 = () => {
        setNestedModal1(!nestedModal1);
        setCloseAll1(false);
    }

    const toggleNested2 = () => {
        setNestedModal2(!nestedModal2);
        setCloseAll2(false);
    }
    // const toggleAll = () => {
    //     setNestedModal(!nestedModal);
    //     setCloseAll(true);
    // }

    const data = useStaticQuery(graphql`
    query MyQuery {
         file(relativePath: { eq: "ceo_banner.jpg" }) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 1220, maxHeight:400,) {
                ...GatsbyImageSharpFluid_withWebp
                }
            }
        } 
    }
  `)
    // <StaticQuery
    //     query={graphql`
    //   query {
    //     Banner: file(relativePath: { eq: "ceo.jpg" }) {
    //         childImageSharp {
    //           fluid(quality: 90, maxWidth: 1220, maxHeight:500,) {
    //             ...GatsbyImageSharpFluid_withWebp
    //           }
    //         }
    //       } 

    //   }
    // `}

    //     render={data => {
    //         const imageData = data.Banner.childImageSharp.fluid
    return (
        <Layout path="/aboutceo/">
            <div className="banner_section">
                <a href="https://www.linkedin.com/in/shabanasiraj/" target="blank">
                    <Img
                        Tag="section"
                        fluid={data.file.childImageSharp.fluid}
                        className="startup_background"
                    ></Img>
                </a>
                <div className="startup">
                    <div className="startup_canter">
                    </div>
                </div>
            </div>
            <section className="startup_section">
                {/* <Modal isOpen={nestedModal} toggle={toggleNested} lassName={className}>
                    <ModalHeader>Nested Modal title</ModalHeader>
                    <ModalBody>Stuff and things</ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={toggleNested}>Done</Button>{' '}
                        <Button color="secondary" onClick={toggleAll}>All Done</Button>
                    </ModalFooter>
                </Modal> */}
                {/* <Modal isOpen={modal} toggle={toggle} className={className}>
                    <ModalHeader toggle={toggle} />
                    <ModalBody>
                        <video object-fit="cover" controls autoplay="autoplay">
                            <source src={Video} type="video/mp4"  ></source>
                        </video>
                    </ModalBody>
                </Modal> */}
                {/* <Modal isOpen={modal1} toggle={toggle1} className={className}>
                    <ModalHeader toggle={toggle1} />
                    <ModalBody>
                        <video object-fit="cover" controls autoplay="autoplay">
                            <source src={Video} type="video/mp4"  ></source>
                        </video>
                    </ModalBody>
                </Modal> */}
                <Container>
                    <Row>
                        <Col md="12">
                            <div className="ceo_text1">
                                <p>Shabana grew up thinking every “no” had to be turned into a “yes”. She thrives on challenges and gets her fuel from being told she ‘can’t. The first MBA from a family and a community, which both challenged and egged her on, she came to the US twenty years ago, with sales and marketing experience from TCS. By then, she had already started and sold two companies and garnered business experience coupled with unshakeable confidence, to take on a different challenge. She joined Alphasoft and learnt the ropes. After seven years of sales, recruiting, project management and account management, she moved to HCL, where after a brief one year period, she was laid off. </p>
                                <p>Seek: Oh yeah? If I can’t join ‘em, I’ll make ‘em. And so Trident was born. </p>
                                <p>Instead of knocking on doors to find another job, Shabana decided to strike it on her own. </p>
                                <p>The hurdles and frustrations Shabana faced during her days as an employee with Tata, Alphasoft, and HCL, inspired her to make her own rules: foremost being transparency. She has made it the foundation of Trident, stressing transparency: client, consultant, and the agency. </p>
                                <p>Strive: Shabana works hard and plays equally hard. Whether it is that elusive niche position she has to fill or the blue skies she wants to touch in the two-seater Cessna, Shabana doesn’t put much stock in luck or chance. What she believes in is hard work, and that it will get her everywhere. And instills that into every last member of her team. Learning to fly or getting the RFP out the door, with the team she worked hard to build, she is a woman on a mission: just keep movin’! </p>
                                <p>Succeed: Shabana defines her own brand of success. Bottomline aside (of course, it matters), she sees success through every increment her team members achieve. Success is when a rookie makes the first submission. And the team lead takes pride in it. </p>
                                <p>Success for Shabana is, when she not only crosses her corporate revenue goals, but surpasses her fund-raising goals for the various charities she’s involved with. Each marathon she runs towards creating awareness and inducting like-minded folks to stand up for children’s education, is a leap towards success. After ten half marathons (including the gorgeous Big Sur 21 miler), the sights are now set on a triathlon. To swim/bike/run… or to just run… that is the question. </p>
                                <p>Shabana’s motto: “Half the fun in succeeding is racing towards it.”</p>
                                <p>She’ll ride whatever it takes to success: a solid client base, a robust team, a Kawasaki Ninja 300, an SL550 or, a Cessna 172R.</p>
                                {/* <p>Shabana grew up thinking every “no” had to be turned into a “yes”. She thrives on challenges and gets her fuel from being told she ‘can’t. The first MBA from a family and a community, which both challenged and egged her on, she came to the US twenty years ago, with sales and marketing experience from TCS. By then, she had already started and sold two companies and garnered business experience coupled with unshakeable confidence, to take on a different challenge. She joined Alphasoft and learnt the ropes. After seven years of sales, recruiting, project management and account management, she moved to HCL, where after a brief one year period, she was laid off. </p>
                                <p>Seek: Oh yeah? If I can’t join ‘em, I’ll make ‘em. And so Trident was born. 
                                   Instead of knocking on doors to find another job, Shabana decided to strike it on her own. 
                                   The hurdles and frustrations Shabana faced during her days as an employee with Tata, Alphasoft, and HCL, inspired her to make her own rules: foremost being transparency. She has made it the foundation of Trident, stressing transparency: client, consultant, and the agency. </p>
                                <p>Strive: Shabana works hard and plays equally hard. Whether it is that elusive niche position she has to fill or the blue skies she wants to touch in the two-seater Cessna, Shabana doesn’t put much stock in luck or chance. What she believes in is hard work, and that it will get her everywhere. And instills that into every last member of her team. Learning to fly or getting the RFP out the door, with the team she worked hard to build, she is a woman on a mission: just keep movin’! </p>
                                <p>Succeed: Shabana defines her own brand of success. Bottomline aside (of course, it matters), she sees success through every increment her team members achieve. Success is when a rookie makes the first submission. And the team lead takes pride in it. 
                                   Success for Shabana is, when she not only crosses her corporate revenue goals, but surpasses her fund-raising goals for the various charities she’s involved with. Each marathon she runs towards creating awareness and inducting like-minded folks to stand up for children’s education, is a leap towards success. After ten half marathons (including the gorgeous Big Sur 21 miler), the sights are now set on a triathlon. To swim/bike/run… or to just run… that is the question. 
                                   Shabana’s motto: “Half the fun in succeeding is racing towards it.”</p>
                                <p>She’ll ride whatever it takes to success: a solid client base, a robust team, Kawasaki Ninja 300, a Mercedes SL550 or, a Cessna 172R.</p> */}
                                {/* <p>Shabana grew up thinking every “no” had to be turned into an “yes”. She thrives on challenges and gets her fuel from being told she ‘can’t. The first MBA from a family and a community that both challenged her and fueled her, she came to the US twenty years ago, with sales and marketing experience from TCS. By then she had already started and sold two companies and garnered business experience coupled with unshakeable confidence to take on a different challenge. She joined Alphasoft and learnt the ropes. After seven years of sales, recruiting, project management and account management, she moved to HCL, where after a brief one year period, she was laid off. </p>
                                <p>Seek: Oh yeah? If I can’t join ‘em, I’ll make ‘em. And so Trident was born. </p>
                                <p>Instead of knocking on doors to find another job, Shabana decided to strike it on her own.  </p>
                                <p>The hurdles and frustrations Shabana faced during her days as an employee with Tata, Alphasoft, and HCL, inspired her to make her own rules: foremost being transparency. She has made it the foundation of Trident, stressing transparency: client, consultant, and the agency. </p>
                                <p>Strive: Shabana works hard and plays equally hard. Whether it is that elusive niche position she has to fill or the blue skies she wants to touch in the two-seater Cessna, Shabana doesn’t put much stock in luck or chance. What she believes in is hard work, and that it will get her everywhere. And instills that into every last member of her team. Learning to fly or getting the RFP out the door, with the team she worked hard to build, she is a woman on a mission: just keep movin’! </p>
                                <p>Succeed: Shabana defines her own brand of success. Bottomline aside (of course, it matters), she sees success through every increment her team members achieve. Success comes to her when a rookie makes her first submission. And the team lead takes pride in it. </p>
                                <p>Success for Shabana, is when she not only crosses her revenue goals, but surpasses her fund raising goals for the various charities she’s involved with. Each marathon she runs, is towards creating awareness and inducting like-minded folks to stand up for children’s education, is a leap towards success. After ten half marathons( including the gorgeous Big Sur 21 miler) , the sights are now set on a triathlon. To swim/bike/run… or to just run… that is the question. </p>
                                <p>Shabana’s motto: “Half the fun in succeeding is racing towards it.”</p>
                                <p>She’ll ride whatever it takes to success: a solid client base, a robust team, Kawasaki Ninja 300, a Mercedes SL550 or, a Cessna 172R.</p> */}
                            </div>
                        </Col>
                        <Col md="4">
                            <div className="about_ceo_img">
                                <img src={Ceo3} />
                            </div>
                            <div className="about_ceo_text">
                                <div className="video_bg">
                                    <div className="text_center">
                                        <h5>Look within for strength, look outside for opportunity, improve, impress, and grow together with your client</h5>
                                    </div>
                                    <div>
                                        <div className="video-play-button" onClick={toggleNested2}>
                                            <img src={Play} alt="img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div toggle={toggleNested2}>
                                <Modal isOpen={nestedModal2} lassName={className} className="modal-dialog_video">
                                    <ModalHeader toggle={toggleNested2}></ModalHeader>
                                    <ModalBody>
                                        <YouTube
                                            opts={youTubeOpts}
                                            videoId="W1gxesz0b1g"
                                            onPlay={true}
                                        />
                                    </ModalBody>
                                </Modal>
                            </div>
                        </Col>
                        <Col sm="4"
                        //  md={{ size: 4, offset: 4 }}
                        >
                            <div className="award_img">
                                <img src={Award} onClick={toggleNested1} />
                                <Modal className="ceo_modal1" isOpen={nestedModal1} toggle={toggleNested1} lassName={className}>
                                    <ModalHeader toggle={toggleNested1}></ModalHeader>
                                    <ModalBody className="ceo_modal_b1">
                                        <img src={Award} onClick={toggleNested1} className="popup_img" />
                                    </ModalBody>
                                </Modal>
                            </div>
                        </Col>
                        <Col md="4">
                            <div className="about_ceo_img">
                                <img src={Ceo2} />
                            </div>
                            <div className="about_ceo_text">
                                <div className="video_bg">
                                    <div className="text_center">
                                        <h5>Shabana speaks  at the Tech Serve Alliance women’s luncheon – Huntington Beach, CA Nov 2019</h5>
                                    </div>
                                    <div>
                                        <div className="video-play-button" onClick={toggleNested}>
                                            <img src={Play} alt="img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div toggle={toggleNested}>
                                <Modal isOpen={nestedModal} lassName={className} className="modal-dialog_video">
                                    <ModalHeader toggle={toggleNested}></ModalHeader>
                                    <ModalBody>
                                        <video object-fit="cover" controls autoplay="autoplay">
                                            <source src={Video2} type="video/mp4"  ></source>
                                        </video>
                                    </ModalBody>
                                </Modal>
                            </div>
                        </Col>
                        <Col md="4">
                            <div className="audio_player_card">
                                <div className="data">
                                    <img src={AudioImg} alt="audio" />
                                    <p>BlueBegum</p>
                                    <h4>High on Life - Meet Shabana Siraj</h4>
                                </div>
                                <AudioPlayer preload="auto" src={"https://episodes.buzzsprout.com/6b9quz1ooaujz66a42692ilfbii8?"} showJumpControls={false} />
                            </div>
                        </Col>
                        <Col md="4">
                            <div className="about_ceo_img">
                                <img src={Ceo1} />
                            </div>
                            <br /><br /><br /><br /><br /><br />
                            <div className="about_ceo_text">
                                <div className="video_bg">
                                    <div className="text_center">
                                        <h5>Shabana speaks at the Staffing Tec conference – Nashville, Tennessee April 2019</h5>
                                    </div>
                                    <div>
                                        <div className="video-play-button" onClick={toggle}>
                                            <img src={Play} alt="img" />
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className={className}>
                                <Modal isOpen={modal} toggle={toggle} className="modal-dialog_first_video" >
                                    <ModalHeader toggle={toggle}></ModalHeader>
                                    <ModalBody >
                                        <video object-fit="cover" controls autoplay="autoplay" className="video_h">
                                            <source src={Video} type="video/mp4"  ></source>
                                        </video>
                                    </ModalBody>
                                </Modal>
                            </div>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col md="4">
                            <div className="ceo_img">
                                <img src={Ceo} width={400} alt="img" />
                            </div>
                        </Col>
                        <Col md="8">
                            <p>Shabana grew up thinking every “no” had to be turned into an “yes”. She thrives on challenges and gets her fuel from being told she ‘can’t’. She came to the US 20 years ago, with sales and marketing experience from Tata (Communications). She joined Alphasoft and learnt the ropes. After 7 years of recruiting, project management and account management, she moved to HCL, where after a brief two year period, she was laid off. </p>
                            <p><strong>Seek: </strong> Oh yeah? If I can’t join ‘em, I’ll make ‘em. And so Trident was born.</p>
                            <p>Instead of knocking on doors to find another job, Shabana decided to strike it on her own.  </p>
                            <p>The hurdles and frustrations Shabana faced during her days as an employee with Tata, Alphasoft, and HCL, inspired her to make her own rules: foremost being transparency. She has made it the foundation of Trident, stressing transparency: client, consultant, and the agency. </p>
                        </Col>
                        <Col md="12">
                            <p><strong>Strive:</strong> Shabana works hard and plays equally hard. Whether it is that elusive niche position she has to fill or the blue skies she wants to touch in the two-seater Cessna, Shabana doesn’t put much stock in luck or chance. What she believes in is hard work, and that it will get her everywhere. And instills that into every last member of her team. Learning to fly or getting the RFP out the door, with the team she worked hard to build, she is a woman on a mission: just keep movin’! </p>
                            <p><strong>Succeed:</strong> Shabana defines her own brand of success. Bottomline aside (of course, it matters), she sees success through every increment her team members achieve. Success comes to her when a rookie makes her first submission. And the team lead takes pride in it.
                            Success for Shabana, is when she not only crosses her revenue goals, but surpasses her fund raising goals for the various charities she’s involved with. Each step she takes towards creating awareness and inducting like-minded folks to stand up for children’s education is, is a leap towards success.
                            </p>
                            <p><strong>Shabana’s motto:</strong> “Half the fun in succeeding is racing towards it.”</p>
                            <p><strong>She’ll ride whatever it takes to success:</strong> a solid client base, a robust team, a 300cc Kawasaki Ninja, a Mercedes SL550 or, a Cessna 172R. </p>
                        </Col>
                    </Row> */}
                </Container>
            </section>
            {/* <section className="ceo_section">
                <Container>
                    <div className="video-play-button">
                        <img onClick={toggle} src={Play} alt="img" />
                    </div>
                    <Modal isOpen={modal} toggle={toggle} className={className}>
                        <ModalHeader toggle={toggle}></ModalHeader>
                        <ModalBody>
                            <video
                                object-fit="cover"
                                controls
                                autoplay="autoplay"
                            >
                                <source src={Video} type="video/mp4"  >
                                </source>
                            </video>
                        </ModalBody>
                    </Modal>
                </Container>
            </section>
            <section className="ceo_img_section">
                <Container>
                    <Row>
                        <Col md="4">
                            <img src={Img1} alt="img" />
                        </Col>
                        <Col md="4">
                            <img src={Img2} alt="img" />
                        </Col>
                        <Col md="4">
                            <img src={Img3} alt="img" />
                        </Col>
                        <Col md="4">
                            <img src={Img4} alt="img" />
                        </Col>

                        <Col md="4">
                            <img src={Img6} alt="img" />
                        </Col>
                        <Col md="4">
                            <img src={Img7} alt="img" />
                        </Col>
                        <Col md="4">
                            <img src={Img5} alt="img" />
                        </Col>
                    </Row>
                </Container>
            </section> */}
            <section className="footer_section">
                <Container>
                    <Row>
                        <Col md="12">
                            <h1>Find out what Trident can do for you.</h1>
                            <div className="footer_button">
                                <a onClick={toggle3}>Contact Us</a>
                            </div>
                            <Modal isOpen={modal3} toggle={toggle3} className={className} className="form_modal">
                                <ModalBody >
                                    <BlogForm title="Find out what Trident can do for you." close={()=> toggle()} />
                                </ModalBody>
                            </Modal>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* <BlogForm refItem={() => { }} title="Find out what Trident can do for you." redirectTitle="About CEO" location={props.location.pathname} /> */}

        </Layout>
    )
}
const StyledBackgroundSection = styled(AboutCEO)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
  margin: 0;
  background-color: inherit !important;
  
  `

export default StyledBackgroundSection